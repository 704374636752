import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  MdArrowBack,
  MdOutlineViewQuilt,
} from "react-icons/md";
import { SiFigma} from "react-icons/si";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import lofi from "../../Assets/Projects/detectLofi.png";
// import mockup from "../../Assets/Projects/lucidityMockup.png";

function Projects() {
  return (
    <Container fluid className="project-section-invidual">

      {/* Banner */}
      <Container fluid style={{paddingLeft: 20, paddingRight: 20}}>
        <Row className="portolioBannerDetect">
          <a href="/project" className="backArrow">
            <MdArrowBack className="backArrowIcon"/>
          </a>
          <h1 className="portolioHeader">
            <strong>detect </strong>
          </h1>
          <p className="portfolioSubtext">
            <MdOutlineViewQuilt/> UX/UI Design 
          </p>
          <div className="d-flex justify-content-center">
                <a className="button-17" target="_blank" rel="noopener noreferrer" role="button" href={"https://www.figma.com/proto/TzzVwbMxWrUCm9x1SZc4As/Entertainment-Finder-App---High-Fidelity-Prototype?node-id=1-2&t=Hyyy5rj6akQ96xRL-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A2"}> <SiFigma size={23}/> &nbsp;<b> View Prototype</b></a>
          </div>
        </Row>
      </Container>

      {/* Progress Bar */}
      <Container className="bar">
        <Row style={{paddingTop: 40}}>
          {/* Link for Pokemon Icons https://www.pokencyclopedia.info/en/index.php?id=sprites/spin-off/ico_shuffle */}
          <ProgressBar position percent={100} filledBackground="linear-gradient(to right, #C889E6, #d345af)">
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#about"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                  <img style={{ paddingTop: 30}} width="30" alt="step1" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_349.png"/>
                  <p style={{fontFamily: "Lato", fontWeight: 600}}> Overview </p>
                </div></a>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#prototype"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                <img style={{ paddingTop: 30}} width="30" alt="step2" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_350.png"/>
                <p style={{fontFamily: "Lato", fontWeight: 600}}> Prototype </p>
              </div></a>
              )}
            </Step>
          </ProgressBar>
        </Row>
      </Container>

      {/* Overview Text */}
      <Container style={{paddingTop: 60, paddingBottom: 30}}>
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="about"> Project Overview </h1>
            <p className="blogText"> 
            Most people can relate to the feeling of not being able to find and decide on a piece of entertainment for the evening. detect is here to solve exactly this problem. 
            detect is an app designed to pick your entertainment based on your inputs. Wanna get one specific choice in a genre of your choosing. Or do you wish to get as specific 
            as possible, by describing the plot of the entertainment. Regardless of how specific you want your search, detect is here to help choose your entertainment!
            </p>
          </Col>
          <Col md={5}>
            <h1 className="blogSubheader"> Components Used </h1>
            <p className="blogComponents"> 
              <SiFigma/> &nbsp; Figma <br></br>
            </p>
          </Col>
        </Row>

        {/* Prototype Text */}
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="prototype"> Prototype </h1>
            <h1 className="blogSubheader"> Low Fidelity Prototype </h1>
            <p className="blogText"> 
            In the initial stages, a couple of low fidelity wireframes were created, depicting the core actions of the application. 
            These frames are initially created to get an overall idea of how the functionality of the application would work. 
            These wireframes serve as the core blueprint developing the high fidelity prototype. You can view the wireframes following this &nbsp;
            <a className="linkInText" target="_blank" rel="noopener noreferrer" href="https://www.figma.com/design/DYRcMycQksVo6gCF9TLzNU/Entertainment-Finder-App---Low-Fidelity-Prototype?node-id=55-2&t=p9SjfucvSBufN9ll-1">Figma link.</a>
            </p>
          </Col>

          <img alt="persona1" src={lofi} style={{borderRadius: 10}}></img>
          <p className="figureText"> Selected frames from the low fidelity prototype</p>
          
          <h1 className="blogSubheader"> High Fidelity Prototype </h1>
            <p className="blogText"> 
            The high fidelity prototype is currently under development. 
            However, the core function of the initial search page is finalized. 
            Please have a look at the following&nbsp;
            <a className="linkInText" target="_blank" rel="noopener noreferrer" href="https://www.figma.com/proto/TzzVwbMxWrUCm9x1SZc4As/Entertainment-Finder-App---High-Fidelity-Prototype?node-id=1-2&t=Hyyy5rj6akQ96xRL-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A2">Figma link</a>  
            &nbsp;if you wish to observe it.</p>

          {/* <img alt="persona1" src={mockup} style={{borderRadius: 40}}></img>
          <p className="figureText"> Selected frames from the high fidelity prototype</p> */}

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
