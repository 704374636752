import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Techstack from "./Techstack";
import aboutImg from "../../Assets/about.jpg";
import Toolstack from "./Toolstack";
import NowPlaying from "./NowPlaying";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        {/* About Me and Image */}
        <Row style={{ justifyContent: "center", paddingTop: "10px", paddingBottom: "30px" }}>
          <Col md={7} style={{ paddingTop: "120px"}}>
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}> Hey, I'm  <strong className="purple">Robin Boe</strong>!</h1>
            <p className="about-text">
            I'm a 23 year old <span className="purple"> UX designer and fullstack developer </span> based in Bergen, Norway. 
            I have a <span className="purple"> Bachelor's degree in Information science </span> and <span className="purple"> Master's in Media- and interaction design</span>, 
            both from University of Bergen (UIB). Through my degrees I have gained valuable skills necessary to understand and research projects, from the ideation to development. 
            Including <span className="purple"> processes </span> such as, user research, usability testing, UX testing, agile development (e.g., Scrum) and data analysis. 
            But also a repertoire of <span className="purple"> tools and skills</span>, including, HTML/CSS, Python, C#, Figma and Unity. 
          </p>
          </Col>
          <Col md={5} style={{ paddingTop: "100px", paddingBottom: "50px" }} className="about-img">
            <img src={aboutImg} alt="about" className="img-fluid" style={{borderRadius: "50%"}}/>
          </Col>
        </Row>
      </Container>

      {/* Spotify */}
      <Container fluid style={{ padding: 0}}>
        <Row className="spotifyRow" style={{ justifyContent: "center", marginBottom: "50px"}}>
          <Col md={"auto"}>
            <h2 className="spotifyHeader"> I'm also passionate about music! </h2>
            <p className="spotifyText"> Take a look at what I'm <strong className="purple">listening to right now! </strong></p>
            <NowPlaying/>
          </Col>
        </Row>
      </Container>

      {/* Skills and Tools */}
      <Container>
        <Row className="skillRow">
          <Col>
            <h1 className="skill-heading">
            <strong className="purple">Skills</strong>
            </h1>

            <Techstack />
          </Col>
          <Col>
            <h1 className="skill-heading">
              <strong className="dark-purple">Tools</strong>
            </h1>
            <Toolstack />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
